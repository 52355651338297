export const addToLocalStorage = (KEY, value) => {
  typeof window !== 'undefined' ? localStorage.setItem(KEY, value) : null;
};

export const getFromLocalStorage = (KEY) => {
  return typeof window !== 'undefined' ? localStorage.getItem(KEY) : null;
};

export const removeFromLocalStorage = (KEY) => {
  typeof window !== 'undefined' ? localStorage.removeItem(KEY) : null;
};

const localStorageService = {
  addToLocalStorage,
  getFromLocalStorage,
  removeFromLocalStorage,
};
export default localStorageService;
