import axios from "axios";
import { ENVIRONMENT_CONFIGS } from "../../../env_config";

const currentEnv = process.env.NEXT_ENVIRONMENT || "development";
console.info("API URL:", ENVIRONMENT_CONFIGS[currentEnv].apiUrl);
console.info("Environment:", process.env.NEXT_ENVIRONMENT);

export const axiosService = axios.create({
  baseURL: ENVIRONMENT_CONFIGS[currentEnv].apiUrl,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "User-Agent":
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:106.0) Gecko/20100101 Firefox/106.0",
    Referer: ENVIRONMENT_CONFIGS[currentEnv].url,
  },
  withCredentials: true,
});

axiosService.interceptors.request.use(
  (config) => {
    console.debug(
      `Sending ${config.method.toUpperCase()} request to: ${config.url}`
    );
    return config;
  },
  (error) => {
    console.error("Request error:", error);
    return Promise.reject(error);
  }
);

axiosService.interceptors.response.use(
  (response) => response,
  (error) => {
    const errorInfo = {
      url: error.config?.url,
      method: error.config?.method,
      status: error.response?.status,
      message: error.message,
      data: error.response?.data,
    };
    console.error("API Request Failed:", errorInfo);

    // Custom error object with relevant info
    const customError = new Error("API request failed");
    customError.info = errorInfo;
    return Promise.reject(customError);
  }
);
