import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { HYDRATE } from "next-redux-wrapper";

export const aventureApi = createApi({
  reducerPath: "aventure",
  tagTypes: [
    "companies",
    "funds",
    "fundraising",
    "search",
    "people",
    "investors",
    "fundPersons",
    "fundInvestment",
    "tags",
    "profile",
    "filters",
    "notifications-settings",
    "notifications",
    "views",
    "listByView",
    "lists",
    "newViews",
    "companiesList",
  ],
  endpoints: (builder) => ({}),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
});
