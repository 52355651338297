// env_config.js

/**
 * Configuration object for different environments
 * This file contains environment-specific settings for the application.
 * It includes configurations for local development, sandbox, development, staging, and production environments.
 * It also includes configurations for media, Strapi CMS, and Sentry error tracking.
 */

const packageJson = require("./package.json");
const NODE_VERSION = packageJson.engines.node;

const currentEnv = process.env.NEXT_ENVIRONMENT;
console.log("Current environment:", currentEnv);

// Function to safely log environment variable status
const safelyLogEnvVarStatus = (key) => {
  console.log(`${key}: ${process.env[key] ? "Set" : "Not set"}`);
};

console.log("Environment variable status:");
[
  "NEXT_PUBLIC_FIREBASE_API_KEY",
  "NEXT_PUBLIC_GOOGLE_MAPS_API_KEY",
  "NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID",
  "NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_SECRET",
  "NEXT_PUBLIC_LINKEDIN_OAUTH_SECRET_ID",
  "NEXT_PUBLIC_NEXTAUTH_SECRET",
  "NEXT_PUBLIC_SENTRY_DSN",
  "NEXT_PUBLIC_STRAPI_API_TOKEN",
  "NEXTAUTH_SECRET",
  "SENTRY_AUTH_TOKEN",
].forEach(safelyLogEnvVarStatus);

const ENVIRONMENT_CONFIGS = {
  nodeVersion: NODE_VERSION,
  misc: {
    atlasAppId: "4d7wu8hn5u",
    googleTagManagerId: "GTM-P4T6Z2M",
  },
  // firebase environment for authentication, keys, and SSO
  firebase: {
    authDomain: "aventure-app-auth.firebaseapp.com",
    projectId: "aventure-app-auth",
    storageBucket: "aventure-app-auth.appspot.com",
    messagingSenderId: "84246390852",
    appId: "1:84246390852:web:60ed2f4771389359733411",
    measurementId: "G-SRDYW9ZWM5",
    repoName: "front-end",
    basicAuthActivated: process.env.NEXT_PUBLIC_TEST === "true",
    accountSettingsInvestor: "user",
    linkedInAuth: {
      clientId: "861zpwwsxbbfd8",
      clientSecret: "NEXT_PUBLIC_LINKEDIN_OAUTH_SECRET_ID",
      scope: "r_liteprofile r_emailaddress",
      authorizationUrl: "https://www.linkedin.com/oauth/v2/authorization",
      tokenUrl: "https://www.linkedin.com/oauth/v2/accessToken",
      profileUrl: "https://api.linkedin.com/v2/me",
      profileParams:
        "(id,localizedFirstName,localizedLastName,profilePicture(displayImage~:playableStreams))",
      redirectPath: "/api/auth/callback/linkedin",
    },
    aliases: ["auth", "authentication", "sso", "firebase"],
  },
  googleOAuth: {
    clientId: "NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID",
    clientSecret: "NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_SECRET",
    redirectPath: "/api/auth/callback/google",
  },
  // Deprecating this to move media to environment specific paths
  // Media environment for images, photos, and videos: prod and dev included
  // media: {
  //   url: "https://images.aventure.vc",
  //  hostname: "images.aventure.vc",
  //  aliases: ["media", "images", "videos"],
  // },
  // Strapi CMS configuration for blog, news, and services
  strapi: {
    hostname: "strapi.aventure.vc",
    url: "https://strapi.aventure.vc",
    aliases: ["cms", "blog", "services"],
  },
  // Sentry error tracking configuration
  sentry: {
    organization: "aventure",
    dsn: "https://55090d2801836087e5189a8fd6bf6ad4@o4506261163802624.ingest.us.sentry.io/4506261194604544",
    enabled: true,
    project: "front-end",
  },
  // Sandbox environment
  sandbox: {
    url: "https://sandbox.aventure.vc",
    apiUrl: "https://sandbox.api.aventure.vc/api",
    apiImageHostName: "https://images.aventure.vc",
    apiNewsImageHostName: "https://images.aventure.vc/images/news/",
    apiHostName: "sandbox.api.aventure.vc",
    googleCloudProject: "sandbox-417216",
    googleCloudServiceAccount: "sandbox-417216@appspot.gserviceaccount.com",
    aliases: ["sandbox"],
  },
  // Development environment
  development: {
    url: "https://dev.aventure.vc",
    apiUrl: "https://dev.api.aventure.vc/api",
    apiImageHostName: "https://images.aventure.vc",
    apiNewsImageHostName: "https://images.aventure.vc/images/news/",
    apiHostName: "dev.api.aventure.vc",
    googleCloudProject: "development-417216",
    googleCloudServiceAccount: "development-417216@appspot.gserviceaccount.com",
    aliases: ["dev", "development"],
  },
  // Staging environment
  staging: {
    url: "https://staging.aventure.vc",
    apiUrl: "https://api.aventure.vc/api",
    apiImageHostName: "https://images.aventure.vc",
    apiNewsImageHostName: "https://images.aventure.vc/images/news/",
    apiHostName: "api.aventure.vc",
    googleCloudProject: "staging-417216",
    googleCloudServiceAccount:
      "staging-service-account@staging-417216.iam.gserviceaccount.com",
    aliases: ["qa", "quality-assurance", "staging", "stage"],
  },
  // Production environment
  production: {
    url: "https://prod.aventure.vc",
    apiUrl: "https://api.aventure.vc/api",
    apiImageHostName: "https://images.aventure.vc",
    apiNewsImageHostName: "https://images.aventure.vc/images/news/",
    apiHostName: "api.aventure.vc",
    googleCloudProject: "production-417216",
    googleCloudServiceAccount: "production-417216@appspot.gserviceaccount.com",
    aliases: ["prod", "production", "main", "master"],
  },
  // Local development environment
  local: {
    url: "http://localhost:3000",
    apiUrl: "https://dev.api.aventure.vc/api",
    apiImageHostName: "https://images.aventure.vc",
    apiNewsImageHostName: "https://images.aventure.vc/images/news/",
    apiHostName: "dev.api.aventure.vc",
    googleCloudProject: "sandbox-417216",
    googleCloudServiceAccount: "sandbox-417216@appspot.gserviceaccount.com",
    aliases: ["local"],
  },
};

/**
 * List of secrets to be loaded from Google Secret Manager*/
const buildSecrets = [
  "NEXT_PUBLIC_FIREBASE_API_KEY",
  "NEXT_PUBLIC_GOOGLE_MAPS_API_KEY",
  "NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID",
  "NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_SECRET",
  "NEXT_PUBLIC_LINKEDIN_OAUTH_SECRET_ID",
  "NEXT_PUBLIC_NEXTAUTH_SECRET",
  "NEXT_PUBLIC_SENTRY_DSN",
  "NEXT_PUBLIC_STRAPI_API_TOKEN",
  "NEXTAUTH_SECRET",
  "SENTRY_AUTH_TOKEN",
];

/**
 * Next.js configuration
 */
const nextConfig = {
  reactStrictMode: true,
  output: "standalone",
  poweredByHeader: false,
  compress: true,
};

const url = ENVIRONMENT_CONFIGS[currentEnv]?.url;
const apiUrl = ENVIRONMENT_CONFIGS[currentEnv]?.apiUrl;
const apiImageHostName = ENVIRONMENT_CONFIGS[currentEnv]?.apiImageHostName;

console.log("Selected URL:", url);
console.log("Selected API URL:", apiUrl);
console.log("Selected API Image Hostname:", apiImageHostName);
const strapiHostUrl = ENVIRONMENT_CONFIGS?.strapi?.url;
const strapi = {
  apiToken: process.env.NEXT_PUBLIC_STRAPI_API_TOKEN,
};
const apiNewsImageHostName =
  ENVIRONMENT_CONFIGS[process.env.NEXT_ENVIRONMENT]?.apiNewsImageHostName;
const getRedirectUrl = (baseUrl, path) => {
  const url = baseUrl.endsWith("/") ? baseUrl.slice(0, -1) : baseUrl;
  return `${url}${path}`;
};

const getRedirectUrls = () => {
  const baseUrl = ENVIRONMENT_CONFIGS[currentEnv]?.url;
  if (!baseUrl) {
    console.error(`No URL configured for environment: ${currentEnv}`);
    return { googleRedirectUrl: "", linkedInRedirectUrl: "" };
  }
  const googleRedirectUrl = getRedirectUrl(
    baseUrl,
    ENVIRONMENT_CONFIGS.googleOAuth.redirectPath
  );
  const linkedInRedirectUrl = getRedirectUrl(
    baseUrl,
    ENVIRONMENT_CONFIGS.firebase.linkedInAuth.redirectPath
  );

  console.log("Current environment:", currentEnv);
  console.log("Base URL:", baseUrl);
  console.log("Google Redirect URL:", googleRedirectUrl);
  console.log("LinkedIn Redirect URL:", linkedInRedirectUrl);

  return {
    googleRedirectUrl,
    linkedInRedirectUrl,
  };
};

module.exports = {
  ENVIRONMENT_CONFIGS,
  buildSecrets,
  nextConfig,
  apiUrl,
  url,
  apiImageHostName,
  strapiHostUrl,
  strapi,
  currentEnv,
  apiNewsImageHostName,
  googleOAuth: {
    ...ENVIRONMENT_CONFIGS.googleOAuth,
    get redirectUrl() {
      return getRedirectUrls().googleRedirectUrl;
    },
  },
  linkedInOAuth: {
    ...ENVIRONMENT_CONFIGS.firebase.linkedInAuth,
    get redirectUrl() {
      return getRedirectUrls().linkedInRedirectUrl;
    },
  },
  NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET,
  getRedirectUrl,
  getRedirectUrls,
};

// Log the final OAuth configurations
console.log("Google OAuth config:", {
  ...module.exports.googleOAuth,
  redirectUrl: module.exports.googleOAuth.redirectUrl,
});
console.log("LinkedIn OAuth config:", {
  ...module.exports.linkedInOAuth,
  redirectUrl: module.exports.linkedInOAuth.redirectUrl,
});

console.log("the new build is done");
