import env_config from "env_config";
import { getApp, getApps, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = getFirebaseConfig();

function getFirebaseConfig() {
  return {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain:
      env_config.ENVIRONMENT_CONFIGS.firebase.authDomain ||
      process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    projectId:
      env_config.ENVIRONMENT_CONFIGS.firebase.projectId ||
      process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket:
      env_config.ENVIRONMENT_CONFIGS.firebase.storageBucket ||
      process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId:
      env_config.ENVIRONMENT_CONFIGS.firebase.messagingSenderId ||
      process.env.NEXT_PUBLIC_FIREBASE_MESSAGE_SENDER_ID,
    appId:
      env_config.ENVIRONMENT_CONFIGS.firebase.appId ||
      process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    measurementId:
      env_config.ENVIRONMENT_CONFIGS.firebase.measurementId ||
      process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
  };
}

// Add these lines to export the full redirect URLs
export const googleRedirectUrl = env_config.googleOAuth.redirectUrl;
export const linkedInRedirectUrl = env_config.linkedInOAuth.redirectUrl;

// Log the redirect URLs
console.log("Firebase config - Google Redirect URL:", googleRedirectUrl);
console.log("Firebase config - LinkedIn Redirect URL:", linkedInRedirectUrl);

export const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
export const auth = getAuth(app);
